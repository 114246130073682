import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Input, DatePicker, Checkbox } from '@lib/components/v2/Form';
import { getFields } from '@lib/utils/v2/getFields';
import { isDobUneditable } from '@lib/utils/v2/isDobUneditable';
import { localizedString } from '@languages';
import classes from './VerifyDetails.style.module.scss';
import { getValidations } from './verifyDetailsValidations';
import { AddressFinder } from '../..';

const VerifyDetails = ({
  idType,
  countryCode: country,
  countryOfIssue,
  onChange,
  address,
  errors,
  checkConfirm,
  showDetailed,
  onShowDetailed,
  addressData,
  engine4Config,
  useIdNumber,
  isOldTurkishDL,
  isSubmiting,
  ...restProps
}) => {
  const {
    FLOW_V2_VERIFY_DETAILS_HEADING = localizedString('checkYourIdDetails'),
    FLOW_V2_VERIFY_DETAILS_DESCRIPTION = null,
    FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT = true,
    FLOW_V2_VERIFY_DETAILS_CONSENT = localizedString('checkYourIdDetailsConsent'),
    FLOW_V2_VERIFY_DETAILS_CONFIRM_DO_NOT_HAVE_A_FIRSTNAME = localizedString('noFirstname'),
    FLOW_V2_VERIFY_DETAILS_CONFIRM_DO_NOT_HAVE_A_MIDDLENAME = localizedString('noMiddlename'),
    FLOW_V2_VERIFY_DETAILS_CONFIRM_DO_NOT_HAVE_A_LASTNAME = localizedString('noSurname'),
    FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 = '',
    FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME = false, // show a checkbox to confirm if firstname/middlename/lastname is empty on verify details page. by default false
    FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME = false,
    FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_ANY_FIRST_DATA_REVIEW = false,
    FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_SECOND_DOCUMENTS = [],
    FLOW_V2_DATEPICKER_FORMAT
  } = process.env;

  const [state, setState] = useState({ checkConfirm });

  const disabledFields = [];

  if (
    isDobUneditable({
      countryCodeIso2: country,
      idType,
      forceRestriction: FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_ANY_FIRST_DATA_REVIEW,
      restrictedSecondDocsList: FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_SECOND_DOCUMENTS
    })
  ) {
    disabledFields.push('dateOfBirth');
  }

  const fields = getFields({
    idType,
    countryOfIssue,
    addressData,
    useIdNumber,
    disabledFields,
    isOldTurkishDL,
    values: restProps
  });

  const formValidations = getValidations({ idType, countryOfIssue, values: restProps });

  const confirmNameIsEmptyFields = {
    firstName: {
      id: 'confirmFirstNameIsEmpty',
      label: FLOW_V2_VERIFY_DETAILS_CONFIRM_DO_NOT_HAVE_A_FIRSTNAME
    },
    middleName: {
      id: 'confirmMiddleNameIsEmpty',
      label: FLOW_V2_VERIFY_DETAILS_CONFIRM_DO_NOT_HAVE_A_MIDDLENAME
    },
    lastName: {
      id: 'confirmLastNameIsEmpty',
      label: FLOW_V2_VERIFY_DETAILS_CONFIRM_DO_NOT_HAVE_A_LASTNAME
    }
  };

  const showEmptyNameCheckbox = (id, fields) => {
    if (!FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
      return false;
    }

    if (!['firstName', 'middleName', 'lastName'].includes(id)) {
      return false;
    }

    if (fields[id] !== '') {
      return false;
    }

    return true;
  };

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field, i) => {
    const { id, label, value, date, addressFinder = false, disabled = false } = field;
    const dataTestIdPrefix = `details-field-${i}-`;
    const labelDataTestId = `${dataTestIdPrefix}lbl`;
    let countries = null;
    if (engine4Config) {
      countries = engine4Config.acceptedCountries;
    }
    // if its date
    if (date) {
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <span data-testid={labelDataTestId}>{field.label}</span>
          <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
            {({ errors: formErrors, setField }) => {
              return (
                <DatePicker
                  key={id}
                  className={classNames(classes.input)}
                  hasError={formErrors[id]}
                  onChange={(value) => {
                    onChange(id, value);
                    setField({ [id]: value });
                  }}
                  id={id}
                  label={label}
                  value={restProps[id]}
                  disabled={disabled || isSubmiting}
                  dataTestId={`${dataTestIdPrefix}txt`}
                  displayedDateFormat={FLOW_V2_DATEPICKER_FORMAT}
                />
              );
            }}
          </Validation>
        </div>
      );
    }
    if (addressFinder) {
      let countryCodeIso2ToVerify;
      if (value && value.manual) {
        countryCodeIso2ToVerify = value.selectedManualCountryCode
          ? value.selectedManualCountryCode
          : 'AU';
      } else {
        countryCodeIso2ToVerify = FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 || country;
      }

      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <span data-testid={labelDataTestId} className={classes['address-label-span']}>
            {field.label}
          </span>
          <AddressFinder
            inputType="textarea"
            data={value}
            showDetailed={showDetailed}
            onShowDetailed={onShowDetailed}
            countryCodeIso2ToVerify={countryCodeIso2ToVerify}
            availableManualCountryCodesIso3={countries}
            checkConfirm={state.checkConfirm}
            onChange={(value) => onChange(id, value)}
            dataTestId={`${dataTestIdPrefix}txt`}
            disabled={isSubmiting}
          />
        </div>
      );
    }
    return (
      <div key={id} className={classNames('', classes.userDetailRow)}>
        <span data-testid={labelDataTestId}>{field.label}</span>
        <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
          {({ dirty, errors: formErrors, fields, setField }) => {
            // manual validating middleName on initializing because init does not validate
            if (
              FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME &&
              id === 'middleName' &&
              value.length === 1
            ) {
              // eslint-disable-next-line no-param-reassign
              dirty.middleName = true;
            }

            return (
              <div>
                <Input
                  key={id}
                  placeholder={field.label}
                  paddingLeft30
                  className={classNames(classes.input, classes.error)}
                  hasError={formErrors[id]}
                  onChange={(value) => {
                    onChange(id, value);
                    setField({ [id]: value });
                  }}
                  {...field}
                  dataTestId={`${dataTestIdPrefix}txt`}
                  disabled={disabled || isSubmiting}
                />
                {showEmptyNameCheckbox(id, fields) && (
                  <Checkbox
                    className={classNames(classes['confirm-name-empty'], classes.error)}
                    name={confirmNameIsEmptyFields[id].id}
                    id={confirmNameIsEmptyFields[id].id}
                    value={fields[confirmNameIsEmptyFields[id]]}
                    label={confirmNameIsEmptyFields[id].label}
                  />
                )}
              </div>
            );
          }}
        </Validation>
      </div>
    );
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading} data-testid="details-heading">
        {FLOW_V2_VERIFY_DETAILS_HEADING}
      </div>
      {FLOW_V2_VERIFY_DETAILS_DESCRIPTION && (
        <div className={classes.description}>{FLOW_V2_VERIFY_DETAILS_DESCRIPTION}</div>
      )}
      <div className={classes.container}>
        {generateFields}
        {FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && (
          <Checkbox
            className={classNames(classes['check-confirm'])}
            id="confirm"
            value={checkConfirm}
            onChange={(value) => {
              setState({ checkConfirm: value });
              onChange('checkConfirm', value);
            }}
            label={FLOW_V2_VERIFY_DETAILS_CONSENT}
            dataTestId="details-checkbox"
            labelDataTestId="details-consentTxt"
            disabled={isSubmiting}
          />
        )}
      </div>
    </div>
  );
};

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  onChange: PropTypes.func,
  onShowDetailed: PropTypes.func,
  errors: PropTypes.object,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,
  showDetailed: PropTypes.bool,
  engine4Config: PropTypes.object,

  countryCode: PropTypes.string,
  countryOfIssue: PropTypes.string,
  addressData: PropTypes.object,
  useIdNumber: PropTypes.bool,
  address: PropTypes.any,
  isOldTurkishDL: PropTypes.bool,
  isSubmiting: PropTypes.bool
};

VerifyDetails.defaultProps = {
  idType: 'NZL_DRIVERLICENCE',
  errors: {},
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {},
  onShowDetailed: () => {},
  showDetailed: false,
  engine4Config: {},
  isOldTurkishDL: false,
  isSubmiting: false
};

export default VerifyDetails;
